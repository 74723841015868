import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
import React from 'react';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import  axios from 'axios';
import { toast } from "react-toastify";
import { RadioChangeEvent } from "antd";

export interface Lesson {
  [key: string]: string
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type: string, filename: string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { attributes: { [key: string]: string | number | object } }[];
  allSubCategories: { attributes: { [key: string]: string | number | object } }[];
  openLeftMenu: boolean;
  darkTheme: boolean;
  CourseInfo: { [key: string]: string | Lesson[] | File | object };
  ChapterInfo: { [key: string]: string | Lesson[] | File | object };
  CategoryInfo: { [key: string]: string | object };
  moduleContent: { [key: string]: string | File };
  allModules: { [key: string]: string | File }[];
  anchorEl: null | HTMLElement;
  publishModal: boolean;
  successModal: boolean;
  publishType: string;
  imagePreview: string;
  videoPreview: string;
  uploadImage: File | null;
  uploadVideo: File | null;
  uploadDoc: File | null;
  suggestions:any;
  activeSuggestionIndex: number | null,
  showDropdown: boolean,
  dropdownPosition: { top: 0, left: 0 }
  content: string,
  selection: any,
  showSuggestions: boolean,
  suggestionBoxPosition: { top: number, left: number},
  open: boolean,
  addNewCourse: boolean;
  addCurriculum: boolean;
  enableChapter: boolean;
  enableListChapter: boolean;
  newChapterAdded: boolean;
  showNewLesson: boolean;
  coverImage: File | null;
  coverPhotoPreview: string;
  promotionalVideo: File | null;
  promotionalVideoPreview: string;
  loading: boolean;
  selectedTagColor: string;
  enableCurriculumChapter: boolean;
  enableLessonSection: boolean;
  lessonType: string;
  enableLessonType: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  apiGetSubCategoryCallId: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  quillRef:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: { data: null, content_type: "", filename: "" },
      selectedCategory: {},
      allCategories: [],
      allSubCategories: [],
      openLeftMenu: false,
      darkTheme: false,
      CourseInfo: {},
      ChapterInfo: {},
      CategoryInfo: {},
      moduleContent: {},
      allModules: [],
      anchorEl: null,
      publishModal: false,
      successModal: false,
      publishType: '',
      imagePreview: '',
      videoPreview: '',
      uploadImage: null,
      uploadVideo: null,
      uploadDoc: null,
      addNewCourse: true,
      addCurriculum: false,
      enableChapter: false,
      enableListChapter: false,
      newChapterAdded: false,
      showNewLesson: false,
      activeSuggestionIndex: null,
      showDropdown: false,
      dropdownPosition: { top: 0, left: 0 },
      content: '',
      selection: null,
      suggestions: [],
      showSuggestions: false,
      suggestionBoxPosition: { top: 0, left: 0 },
      open: false,
      coverImage: null,
      coverPhotoPreview: '',
      loading: false,
      promotionalVideo: null,
      promotionalVideoPreview: '',
      selectedTagColor: "",
      enableCurriculumChapter: false,
      enableLessonSection: false,
      lessonType: "",
      enableLessonType: false
    };
    this.quillRef = React.createRef();
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    }
    else {
      this.setState({ darkTheme: false })
    }

    if (this.quillRef.current) {
      const editor = this.quillRef.current.getEditor();
      const editorElement = editor.root;
      editorElement.setAttribute('spellcheck', 'true');
    }
    this.getAllCategory()
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({ refresh: false, publishModal: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.apiPostItemCallId) {
          this.setState({ PostData: responseJson.data, refresh: false });
        } else if (apiRequestCallId === this.addpostApiCallId) {
          if (responseJson?.error) {
            this.setState({ loading: false })
            toast.error(responseJson.error)
          } else {
            this.setState({
              refresh: true, successModal: true, loading: false, allModules: [],
              moduleContent: { 'title': "", 'lesson_name': "", 'lesson_number': "" },
              imagePreview: "", videoPreview: "", uploadDoc: null
            });
          }
        } else if (apiRequestCallId === this.updatePostApiCallId) {
          this.setState({ refresh: true });
          this.getPostData();
          alert(configJSON.PostUpdated)
          this.props.navigation.goBack();
        } else if (apiRequestCallId === this.DeleteApiCallId) {
          this.getPostData();
        } else if (apiRequestCallId === this.apiGetCategoryCallID) {
          this.setState({ allCategories: responseJson.data })
        } else if (apiRequestCallId === this.apiGetSubCategoryCallId) {
          this.setState({ allSubCategories: responseJson.data })
        }
      } else if (errorReponse) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item:{attributes: { name:string, id:string}} | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  editNavigation = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
      name: this.state.name,
      description: this.state.description,
      body: this.state.description,
      price: this.state.price,
      currency: "$",
      category_id: this.state.category_id,
      sub_category_id: 1,
      image: this.state.profileImageData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllCategory = async () => {
    this.apiGetCategoryCallID = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.getAllCategoryEndPoint,
    });
  }

  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(recordId: string) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        name: this.state.name,
        description: this.state.description,
        body: this.state.description,
        sub_category_id: 1,
        price: this.state.price,
        currency: "$",
        category_id: this.state.category_id,
        image: this.state.profileImageData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${recordId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  deleteRecord(recordId: string) {
    Alert.alert(
      configJSON.Warning,
      configJSON.MessageForDelete,
      [
        { text: "No", onPress: () => {}, style: "cancel" },
        {
          text: "Yes",
          onPress: () => {
            this.setState({ refresh: true });
            this.delete(recordId);
          }
        }
      ],
      { cancelable: false }
    );
  }

  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  handleCatChangeChange = (selectedOption: ValueType<OptionTypeBase, false>) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption?.value
    });
  };

  leftMenuHandle = () => {
    this.setState({ openLeftMenu: !this.state.openLeftMenu })
    setStorageData('open', JSON.stringify(!this.state.openLeftMenu))
  }

  handleTheme = () => {
    this.setState({ darkTheme: !this.state.darkTheme })
    setStorageData('darkMode', `${!this.state.darkTheme}`)
  }

  changeCourseInfo = async (valueKey: string, value: string) => {
    if (valueKey === 'category_attributes') {
      this.setState({ CategoryInfo: { ...this.state.CategoryInfo, ['name']: value } });
      this.apiGetSubCategoryCallId = await this.apiCall({
        method2: configJSON.validationApiMethodType,
        contentType2: configJSON.validationApiContentType,
        endPoint2: `${configJSON.getAllSubCategoryEndPoint}/${value}`,
      });

    }
    else if (valueKey === 'sub_category')
      this.setState({ CategoryInfo: { ...this.state.CategoryInfo, ['sub_categories_attributes']: [{ 'name': value }] } });
    else {
      this.setState({
        CourseInfo: { ...this.state.CourseInfo, [valueKey]: value }
      });
    }
  };

  changeChapterInfo = async (valueKey: string, value: string) => {
    this.setState({
      ChapterInfo: { ...this.state.ChapterInfo, [valueKey]: value }
    });
  };

  changeLessonInfo = (valueKey: string, value: string) => {
    this.setState({ moduleContent: { ...this.state.moduleContent, [valueKey]: value } });
  };

  addLessonDetails = () => {
    let values = this.state.allModules;
    values.push(this.state.moduleContent)
    this.setState({ allModules: values, moduleContent: { 'title': "", 'lesson_name': "", 'lesson_number': "" }, imagePreview: "", videoPreview: "", uploadDoc: null })
    toast.success("Course Content Added")
  }

  openPublishModal = () => {
    if (!this.state.CourseInfo["course_name"]) {
      toast.error("Please enter course name", { containerId: 'A' })
      return
    }
    if (!this.state.CourseInfo["course_description"]) {
      toast.error("Please enter course description", { containerId: 'A' })
      return
    }
    if (!this.state.CategoryInfo) {
      toast.error("Please enter category", { containerId: 'A' })
      return
    }
    this.setState({ publishModal: true })
  }

  closeModals = () => {
    this.setState({ publishModal: false, successModal: false, publishType: "" })
  }

  closeSuccessModal = () => {
    this.setState({ newChapterAdded: false, publishModal: false, successModal: false, CourseInfo: {}, moduleContent: {} })
  }

  changePublishType = (event: RadioChangeEvent) => {
    this.setState({ publishType: event.target.value, CourseInfo: { ...this.state.CourseInfo, ['publish_type']: event.target.value } })
  }

  uploadCover = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      this.setState({ coverImage: file, coverPhotoPreview: URL.createObjectURL(file) })
    }
    if (file && file.type.startsWith('video/')) {
      this.setState({ promotionalVideo: file, promotionalVideoPreview: URL.createObjectURL(file) })
    }
  }

  uploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: null })
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      this.setState({ uploadImage: file, imagePreview: URL.createObjectURL(file) })
      this.setState({ moduleContent: { ...this.state.moduleContent, ['image']: file } });
    }
    if (file && file.type.startsWith('video/')) {
      this.setState({ uploadVideo: file, videoPreview: URL.createObjectURL(file) })
      this.setState({ moduleContent: { ...this.state.moduleContent, ['video']: file } });
    }
    if (file && file.type.startsWith('application/')) {
      this.setState({ uploadDoc: file })
      this.setState({ moduleContent: { ...this.state.moduleContent, ['document']: file } });
    }
  }

  apiCall = async (data: { [key: string]: string }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );

    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  addNewCourse = async () => {
    let body = {
      "course": {
        "course_name": this.state.CourseInfo['course_name'],
        "image": this.state.coverImage,
        "course_description": this.state.CourseInfo['course_description'],
        "tags": this.state.CourseInfo['tags'],
        "course_contents_attributes": this.state.allModules,
        "publish_type": this.state.publishType,
        "category_attributes": this.state.CategoryInfo,
      },
      // "company_ids": [1]
    }
    this.setState({ loading: true, publishModal: false })
    this.addpostApiCallId = await this.apiCall({
      method2: configJSON.PostAPiMethod,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.createPostEndPoint,
      body2: JSON.stringify(body)
    });
  }

  checkSpelling = async (content:any) => {
    try {
      const response = await axios.get('https://api.textgears.com/spelling', {
        params: {
          key: 'yJ85cVqAAMVkya7O',
          text: content
        }
      });

  const suggestions1 = response.data.response.errors.map((error:any) => ({
        word: error.bad,
        suggestions: error.better,
      }));
  this.setState({suggestions: suggestions1});
      return suggestions1;
    } catch (error) {
      console.error('Error checking spelling:', error);
    }
  };

  handleWordClick = (index: number, event: any) => {
    const rect = event.target.getBoundingClientRect();
    this.setState({
      activeSuggestionIndex: index,
      showDropdown: true,
      dropdownPosition: {
        top: rect.bottom,
        left: rect.right
      }
    });
  };

handleSelectionChange = (range:any) => {

    if (range && range.length > 0) {
      const quill = this.quillRef.current.getEditor();
      const selectedText = quill.getText(range.index, range.length);
      this.checkSpelling(selectedText);

      const bounds = quill.getBounds(range.index, range.length);

      const suggestionBoxPosition = {
      top: bounds.top + bounds.height + window.scrollY+290,
      left: bounds.left + window.scrollX+150
      };

      this.setState({
        selection: range,
        showSuggestions: true,
        suggestionBoxPosition
      });
    } else {
      this.setState({ showSuggestions: false });
    }
  };


handleSuggestionClick = (suggestion:any) => {
    const { selection } = this.state;
    const quill = this.quillRef.current.getEditor();
    if (selection) {
      quill.deleteText(selection.index, selection.length);
      quill.insertText(selection.index, suggestion);
      quill.setSelection(selection.index + suggestion.length);
    }

    this.setState({ showSuggestions: false });
  };

  handleOpen = () => {
  this.setState({open: true})
  };

  handleClose = () => {
  this.setState({open: false})
  };

  removeCoverImage = () => {
    this.setState({ coverPhotoPreview: "", coverImage: null })
  }

  removePromotionalVideo = () => {
    this.setState({ promotionalVideoPreview: "", promotionalVideo: null })
  }

  chooseTagColor = (color: string) => {
    this.setState({ selectedTagColor: color })
  }

  continueCourse = () => {
    this.setState({ enableCurriculumChapter: true, addCurriculum: true, addNewCourse: false, enableChapter: true })
  }

  continueLesson = () => {
    this.setState({ addCurriculum: false, enableLessonSection: true, enableListChapter: false })
  }

  setLessonType = (type: string) => {
    this.setState({ lessonType: type, enableLessonSection: false, enableLessonType: true })
  }

  saveAndClose = () => {
    this.setState({ lessonType: "", enableLessonSection: true })
  }
  // Customizable Area End
}
