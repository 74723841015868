import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface CourseAttributes {
  course_name: string;
}

interface CourseData {
  data: {
    attributes: CourseAttributes;
  };
}

interface SearchStudentCourseAssignmentAttributes {
  status: string;
  group: string;
  created_at: string;
  course: CourseData;
}

interface SearchStudentCourseAssignment {
  id: string;
  type: string;
  attributes: SearchStudentCourseAssignmentAttributes;
}

interface SearchDataResponse {
  data: SearchStudentCourseAssignment[];
  meta: {
    result_courses_count: number;
  };
}
interface SearchHistoryItem {
  courseName: string;
  groupName: string;
  status: string;
  sort: string;
}

interface SearchHistoryResponse {
  history: HistoryItem[];
  meta: MetaData;
}
interface HistoryItem {
  id: string;
  type: string;
  attributes: HistoryAttributes;
}
interface HistoryAttributes {
  id: number;
  account_id: number;
  created_at: string;
  course: CourseData;
  student_course_assignment: StudentCourseAssignmentData;
}
interface CourseData {
  data: {attributes:CourseAttributes};
}
interface StudentCourseAssignmentData {
  data: StudentCourseAssignment | null;
}
interface StudentCourseAssignment {
  id: string;
  type: string;
  attributes: StudentCourseAssignmentAttributes;
}
interface StudentCourseAssignmentAttributes {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  progress_percentage: string;
  expiration_date: string;
  red_label: string | null;
  group: string;
  course: CourseDetail;
  profile: ProfileDetail;
}
interface CourseDetail {
  data: CourseInfo;
  included: CourseContent[];
}
interface CourseDataResponse {
  data: CourseDataItem[];
  meta: {
    result_courses_count: number;
  };
}
interface CourseDataItem {
  id: string;
  type: string;
  attributes: CourseAttributes;
}
interface CourseInfo {
  id: string;
  type: string;
  attributes: CourseAttributes;
  relationships: CourseRelationships;
}
interface CourseAttributes {
  course_name: string;
  course_description: string | null;
  category: string | null;
  sub_category: string | null;
  sub_sub_category: string | null;
  language: string | null;
  price: string | null;
  level: string | null;
  tags: string | null;
  duration: string | null;
  year: string | null;
  is_assigned: boolean;
  is_completed: boolean;
  profile_id: number;
  role_id: string;
  preferred_name: string;
  media_type: string | null;
  publish_type: string | null;
  due_date: string | null;
  course_contents_count: number;
  image: string | null;
  video_url: string | null;
  document_url: string | null;
}
interface CourseRelationships {
  course_contents: {
    data: CourseContentData[];
  };
}
interface CourseContentData {
  id: string;
  type: string;
}
interface CourseContent {
  id: string;
  type: string;
  attributes: CourseContentAttributes;
}
interface CourseContentAttributes {
  course_id: number;
  title: string | null;
  lesson_name: string | null;
  lesson_number: string | null;
  video: string | null;
  document: string | null;
}
interface ProfileDetail {
  data: ProfileInfo;
}
interface ProfileInfo {
  id: string;
  type: string;
  attributes: ProfileAttributes;
}
interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number;
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}
interface MetaData {
  total_pages: number;
  current_page: number;
  per_page: number;
  message: string;
}
type FilterCategory = 'cource' | 'status' | 'sort';
interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  sidOpen: boolean;
  darkMode: boolean;
  isOpen: boolean;
  searchHistory: {
   id:string; courseName: string; groupName: string; status: string; sort: string,searchHistoryIcon:boolean
  }[];
  searchAllHistory: {
   id:string; courseName: string; groupName: string; status: string; sort: string,
  }[];
  error: string;
  filters: boolean;
  history: boolean;
  allFilters: {
    course: string[];
    status: string[];
    sort: string[];
  };
  allSelectedFilters: {
    "cource": string[];
    "status": string[];
    "sort": string[];
  };
  selectedFilters: string[],
  modalOpen: boolean;
  isLoading: boolean;
  searchResults: any[],
  searchQuery: string,
  openSaveFilters: boolean;
  selectedTheme: boolean;
  anchorEl: null;
  profileData:ProfileData;
  openEmptySearch:boolean;
  roleId:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getSearchBarApiCallId = "";
  getSearchHistoryApiCallId = "";
  deleteSearchHistoryApiCallId = "";
  getAdvacedSearchApiCallId="";
  apiProfileGETCallId="";
  postSearchHistoryApiCallId="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      sidOpen: false,
      darkMode: false,
      isOpen: false,
      searchHistory: [],
      searchAllHistory: [],
      error: "",
      filters: false,
      history: false,
      allFilters: {
        course: [],
        status: [],
        sort: [],
      },
      allSelectedFilters:{
        "cource":[],
        "status":[],
        "sort":[]
      },
      selectedFilters: [],
      modalOpen: false,
      isLoading: false,
      searchResults: [],
      searchQuery: "",
      openSaveFilters: false,
      selectedTheme: false,
      anchorEl: null,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: 0,
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
      openEmptySearch:false,
      roleId:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getUserProfileData();
    this.checkDarkMode();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSessionResponse(message: Message) {
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
    this.setState({ token: token });
  }

  handleApiResponse(message: Message) {
    runEngine.debugLog("Message Received", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    runEngine.debugLog("API Message Received", message);

    if (responseJson) {
      this.processResponseJson(apiRequestCallId, responseJson);
    }
  }

  processResponseJson(apiRequestCallId: string, responseJson: any) {
    if (responseJson.accounts) {
      this.handleAccountsResponse(responseJson.accounts);
    } else if (responseJson.errors) {
      this.handleErrorResponse(apiRequestCallId);
    }

    this.handleSpecificApiResponses(apiRequestCallId, responseJson);
  }

  handleAccountsResponse(accounts: any) {
    if (typeof accounts === "string") {
      alert(accounts);
    } else {
      this.setState({ advancedsearchList: accounts.data });
    }
  }

  handleErrorResponse(apiRequestCallId: string) {
    if (apiRequestCallId === this.advancedsearchApiCallId) {
      this.showAlert("Alert", "API Error", "");
    }
  }

  handleSpecificApiResponses(apiRequestCallId: string, responseJson: any) {
    if (this.getSearchBarApiCallId === apiRequestCallId && !responseJson.errors) {
      this.handleSearchByRole(responseJson);
    } else if (this.getSearchHistoryApiCallId === apiRequestCallId && !responseJson.errors) {
      this.setState({
        searchAllHistory: this.transformHistoryData(responseJson),
        isLoading: false,
      });
    } else if (this.deleteSearchHistoryApiCallId === apiRequestCallId && !responseJson.errors) {
      this.setState({
        searchAllHistory: [],
        isLoading: false,
      });
    } else if (this.apiProfileGETCallId === apiRequestCallId && !responseJson.errors) {
      this.setState({
        roleId: responseJson.role_id,
        isLoading: false,
      },()=>{this.setFiltersForRoles()});
    } else {
      this.setState({
        isLoading: false,
        error: responseJson.errors || "Unknown error",
        searchHistory: [],
        searchAllHistory: [],
      });
    }
  }
  handleSearchByRole=async (responsedata:any)=>{
    if(this.state.roleId==="worker"){
      this.setState({
        searchHistory: this.transformSearchData(responsedata),
        isLoading: false,
      });
    } else {
      this.setState({
        searchHistory: await this.transformSearchDataRole(responsedata),
        isLoading: false,
      });
    }
  }
  setFiltersForRoles=()=>{
    if(this.state.roleId==="worker"){
      const newFilters = {
        course: ["title", "group"],
        status: ["in_progress", "completed"],
        sort: ["status", "relevance"],
      }
      this.setState({
        allFilters:newFilters,
      });
    } else if(this.state.roleId==="instructor"){
      const newFilters = {
        course: ["title", "group","instructor"],
        status: ["all","in_progress", "completed","expired","assigned"],
        sort: ["popularity", "creation_date"],
      }
      this.setState({
        allFilters:newFilters,  
      });
    }
    else if(this.state.roleId==="supervisor"){
      const newFilters = {
        course: ["title", "group","instructor"],
        status: ["hidden","private","pre_order","published"],
        sort: ["complication_rate", "student_engagement"],
      }
      this.setState({
        allFilters:newFilters,  
      });
    }
  }
  setHistoryStatus=(is_assigned:boolean,is_completed:boolean)=>{
    let status:string="";
    if(this.state.roleId==="worker"){
      if(is_assigned && is_completed){
        status="Assigned";
      } 
      if(is_assigned){
        status="Completed";
      }
    } else {
      if(is_assigned){
        status="DRAFT";
      } 
      if(is_assigned && is_completed){
        status="PUBLISHED";
      }
    }
    return status;
  }
  transformHistoryData(historyData: SearchHistoryResponse) {
    if (!historyData || !historyData.history) {
      return [
        {
          id:'',
          courseName: '',
          groupName: '',
          status: '',
          sort: '',
        },
      ];
    }
    return historyData.history.map((item:any) => {
      const studentCourseAssignment = item.attributes.course.data;
      const courseInfo = studentCourseAssignment.attributes;
      let statusInfo = this.setHistoryStatus(studentCourseAssignment.attributes.is_assigned,studentCourseAssignment.attributes.is_completed);
      return {
        id:studentCourseAssignment.id,
        courseName: courseInfo.course_name,
        groupName: studentCourseAssignment.attributes.group,
        status: statusInfo,
        sort: item.attributes.created_at,
      };
    });
  }
  transformSearchData(searchData: SearchDataResponse) {
    if (!searchData || !searchData.data) {
      return [];
    }
    return searchData.data.map((item: any) => {
      const id = item.attributes.course.data.id;
      const courseName = item.attributes.course.data.attributes.course_name;
      const status = item.attributes.status;
      const groupName = item.attributes.group;
      const sort = this.setRelevanceStudent(item.attributes.course.data.attributes.course_name);
      const searchHistoryIcon = item.attributes.is_in_search_icon;
      return {
        id,
        courseName,
        groupName,
        status,
        sort,
        searchHistoryIcon,
      };
    });
  }
  setStatusForRoles=async (is_assigned:boolean,is_completed:boolean,hidden:boolean,private_status:boolean,pre_order:boolean)=>{
    let status:string ="";
    if(is_assigned===true && is_completed===true){
      status = "Published";
    } else if(hidden===true){
      status="Hidden"
    } else if(private_status===true){
      status="Private"
    } else if(pre_order===true){
      status="Pre-Order"
    } else if(is_assigned===true){
      status = "Assigned"
    } else if(is_assigned===false){
      status = "Draft"
    }
    return status
  }
  setRelevance=(queryItem:string)=>{
    if(queryItem.toLowerCase().includes(this.state.searchQuery)){
      return "Most Popular";
    }
    else {
      return "";
    }
  }
  setRelevanceStudent=(queryItem:string)=>{
    if(queryItem.toLowerCase().includes(this.state.searchQuery)){
      return "Most Relevant";
    }
    else {
      return "";
    }
  }
  transformSearchDataRole=async(searchData: SearchDataResponse)=> {
    if (!searchData || !searchData.data) {
      return [];
    }
    const transformedData = await Promise.all(
      searchData.data.map(async (item: any) => {
        const id = item.id;
        const courseName = item.attributes.course_name;
        const status = await this.setStatusForRoles(
          item.attributes.is_assigned,
          item.attributes.is_completed,
          item.attributes.hidden,
          item.attributes.private,
          item.attributes.pre_order
        );
        const groupName = item.attributes.preferred_name;
        const sort = await this.setRelevance(item.attributes.course_name);
        const searchHistoryIcon = item.attributes.is_in_search_icon;
      
        return {
          id,
          courseName,
          groupName,
          status,
          sort,
          searchHistoryIcon,
        };
      })
    );
  
    return transformedData;
  }

  handleAddToSearchHistory = async (id: string) => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const search_history={
      course_id:id,
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postSearchHistoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.addSearchHistoryEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(search_history));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(message.id, message);
  };

  handleSearch = async (query: string) => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const filters:any = {};
    if(this.state.allSelectedFilters["cource"].length>0){
      filters["cource"]= this.state.allSelectedFilters["cource"];  
    }
    if(this.state.allSelectedFilters["status"].length>0){
      filters["status"]= this.state.allSelectedFilters["status"];  
    }
    if(this.state.allSelectedFilters["sort"].length>0){
      filters["sort"]= this.state.allSelectedFilters["sort"];  
    }
    const attrs={
      q:query,
      filters
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSearchBarApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getAdvacedSearchApiEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(attrs));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(message.id, message);
  };
 
  handleSearchHistory = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSearchHistoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSearchHistoryApiEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(message.id, message);
  };

  handleDeleteAll = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSearchHistoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.deleteSearchHistoryApiEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");

    runEngine.sendMessage(message.id, message);
  };

  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  handleClose = () => {
    this.setState({ isOpen: false, filters: false, history: false, openSaveFilters: false });
  };

  handleFiltersBack = () => {
    this.setState({ filters: false, history: false, isOpen: true, openSaveFilters: false })
  };

  handleFilters = () => {
    this.setState({ isOpen: false, filters: true, history: false, openSaveFilters: false });
  };

  handleHistory = async () => {
    this.setState({ history: true, isOpen: false, filters: false, openSaveFilters: false });
    await this.handleSearchHistory();
  };
  openHistory = async()=>{
    this.setState({ searchQuery:"", isOpen: true }); 
    await this.handleSearch(this.state.searchQuery);
  }
  handleRemoveFilter = (category: string, filter: string) => {
    const selectedFilters = this.state.selectedFilters.filter((f) => f !== filter);
    this.setState({ selectedFilters });
  };

  handleChipClick = (chip: string) => {
    this.setState((prevState) => {
      const selectedChips = [...prevState.selectedFilters];
      const index = selectedChips.indexOf(chip);
      if (index === -1) {
        selectedChips.push(chip);
      } else {
        selectedChips.splice(index, 1);
      }
      return { selectedFilters: selectedChips };
    });
  };
  handleSelectedFilters = (key:FilterCategory,value:string)=>{
    this.setState((prevState) => {
      const updatedFilters = { ...prevState.allSelectedFilters };

      if (updatedFilters[key].includes(value)) {
        updatedFilters[key] = updatedFilters[key].filter(
          (item: string) => item !== value
        );
      } else {
        updatedFilters[key].push(value);
      }
      return { allSelectedFilters: updatedFilters };
    });
  }
  formatStatus = (status: string): string => {
    return status.toUpperCase().replace(/_/g, ' ');
  };
  formatStatusFilter = (status: string): string => {
    return status
      .replace(/[_-]/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };
  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false });
    
  }

  handleDelete = async () => {
    await this.handleDeleteAll();
    this.setState({ modalOpen: false });
  }
  handleReset = async () => {
    this.setState({ selectedFilters: [],allSelectedFilters:{
      "cource":[],
      "status":[],
      "sort":[]
    },filters: false, history: false, isOpen: true, openSaveFilters: false});
    await this.handleSearch(this.state.searchQuery);

  }
  goToFilters = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Filteritems");
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: route });
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleInputChange = async (event: any) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery, isOpen: true }); 
    await this.handleSearch(searchQuery);
  }

  handleSaveFilters = () => {
    this.setState({ isOpen: false, filters: false, history: false, openSaveFilters: true });
    this.handleSearch(this.state.searchQuery);
  }

  gotoPage = (page: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), page);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  checkDarkMode=()=>{
    let mode = JSON.parse(localStorage.getItem('darkMode')!);
    if(mode)
      this.setState({
        darkMode:mode
      })
  }
  // Customizable Area End
}
