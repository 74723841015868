export const userProfile = require("../assets/user-profile.png");
export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const mailIconDark = require("../assets/mailIconDark.png");
export const bellIconDark = require("../assets/bellIconDark.png");
export const logoutIcon = require("../assets/logoutIcon.png");
export const logoutIconDark = require("../assets/logoutIconDark.png");
export const settingsIcons = require("../assets/settingsIcons.png");
export const settingsIconDark = require("../assets/settingsIconDark.png");
export const termsIcon = require("../assets/termsIcon.png");
export const termsIconDark = require("../assets/termsIconDark.png");